<template>
    <div class="main">
      <div class="typewriter">
        <p>Born from the expertise of computer vision doctorates,</p>
        <p>IVIS LABS, short for Intelligent Vision Labs,</p>
        <p>symbolizes the pinnacle of innovation in digital vision technology.</p>
      </div>
  
      <div class="s-1">
        <h1>LEADING OUR MISSION!!!</h1>
        <div class="ss-1-container">
          <p class="intro">
            Dr. Vinay has gained extensive experience over the past 14 years in various fields, including
            Corporate, Academia, and Research. His PhD in Computer Science with an AI specialization has led him to
            explore the creation of real-time Artificial Intelligence applications, which ultimately led to the
            establishment of IVIS LABS Private Limited.
          </p>
          <div class="ss-1">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img class="title" src="https://ivislabsdocs.s3.ap-south-1.amazonaws.com/profile-pic.jpg" alt="Profile Picture">
                </div>
                <div class="flip-card-back">
                  <p style="font-weight: 900;"><strong>Founder & CEO</strong></p>
                  <p class="title">Dr. Vinay Kumar Venkataramana</p>
                  <div class="title-flex">
                    <a :href="linkedinUrl" target="_blank">
                      <div class="Social-Btn">
                        <span class="svgContainer">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 496 496" xml:space="preserve"
                          fill="#000000">
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                          <g id="SVGRepo_iconCarrier">
                            <g>
                              <g>
                                <rect id="SVGCleanerId_0" x="16" y="168.728" style="fill:#187FB8;" width="96"
                                  height="304"></rect>
                              </g>
                              <g>
                                <rect id="SVGCleanerId_0_1_" x="16" y="168.728" style="fill:#187FB8;" width="96"
                                  height="304"></rect>
                              </g>
                            </g>
                            <g>
                              <rect id="SVGCleanerId_0_2_" x="16" y="168.728" style="fill:#1267A0;" width="96"
                                height="304"></rect>
                            </g>
                            <path style="fill:#187FB8;" d="M16,340.712c24,9.616,56,17.664,96,23.88V168.728H16V340.712z">
                            </path>
                            <path style="fill:#2499CE;" d="M16,207.92c24,9.624,56,17.664,96,23.88v-63.072H16V207.92z">
                            </path>
                            <path style="fill:#187FB8;"
                              d="M374.912,164.944c-55.4,0-94.912,29.976-94.912,51v-47.216H168.208c1.408,24,0,304,0,304H280V308.424 c0-9.2-2.032-18.416,0.76-25.04c7.472-18.416,21.52-37.512,50.368-37.512c37.712,0,52.872,28.312,52.872,69.8v157.056h112V303.864 C496,208.984,443.608,164.944,374.912,164.944z">
                            </path>
                            <path style="fill:#1267A0;"
                              d="M374.912,164.944c-55.4,0-94.912,29.976-94.912,51v-47.216H168.208c1.408,24,0,304,0,304H280V308.424 c0-9.2-2.032-18.416,0.76-25.04c7.472-18.416,21.52-37.512,50.368-37.512c37.712,0,52.872,28.312,52.872,69.8v157.056h112V303.864 C496,208.984,443.608,164.944,374.912,164.944z">
                            </path>
                            <path style="fill:#187FB8;"
                              d="M374.912,164.944c-55.4,0-94.912,29.976-94.912,51v-47.216H168.208 c0.832,16,0.672,121.344,0.4,204.592c34.624,3.832,71.392,5.744,111.392,5.824v-70.72c0-9.2,0.208-11.048,0.208-11.048 c0-28.904,22.064-51.504,50.92-51.504c37.712,0,52.872,28.312,52.872,69.8v57.808c40-4.168,77.976-10.56,112-18.824v-50.8 C496,208.984,443.608,164.944,374.912,164.944z">
                            </path>
                            <g>
                              <path style="fill:#2499CE;"
                                d="M374.912,164.944c-55.4,0-94.912,29.976-94.912,51v-47.216H168.208 c0.408,8,0.576,35.344,0.616,72.112c35.224,3.888,72.152,5.76,110.192,5.76c72.096,0,140.904-7.584,201.08-20.776 C459.008,184.72,420.68,164.944,374.912,164.944z">
                              </path>
                              <path style="fill:#2499CE;"
                                d="M59.064,23.272C23.352,23.272,0,45.792,0,76.048c0,29.688,22.68,52.68,57.68,52.68h0.704 c36.336,0,58.96-23.08,58.96-52.752C116.664,45.728,94.728,23.272,59.064,23.272z">
                              </path>
                            </g>
                            <path style="fill:#187FB8;"
                              d="M59.064,52.08C23.352,52.08,0,45.792,0,76.048c0,29.688,22.68,52.68,57.68,52.68h0.704 c36.336,0,58.96-23.08,58.96-52.752C116.664,45.728,94.728,52.08,59.064,52.08z">
                            </path>
                            <path style="fill:#1267A0;"
                              d="M59.064,96.016C23.352,96.016,0,66.624,0,76.048c0,29.688,22.68,52.68,57.68,52.68h0.704 c36.336,0,58.96-23.08,58.96-52.752C117.136,66.568,94.248,96.016,59.064,96.016z">
                            </path>
                          </g>
                        </svg>
                      </span>
                        <span class="BG">LinkedIn</span>
                      </div>
                    </a>
                    <div class="Social-Btn">
                      <a :href="emailUrl">
                        <span class="svgContainer">
                      <svg height="200px" width="200px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve"
                        fill="#000000">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                          <path style="fill:#0071CE;"
                            d="M512,132.694c0-20.004-16.367-36.371-36.372-36.371H36.372C16.367,96.322,0,112.689,0,132.694 v246.613c0,20.004,16.367,36.371,36.372,36.371h439.255c20.005,0,36.372-16.367,36.372-36.371V132.694L512,132.694z">
                          </path>
                          <path style="fill:#0055B8;"
                            d="M5.41,398.324c2.997,4.849,7.098,8.95,11.945,11.947L180.27,247.357l-11.945-11.947L5.41,398.324z M343.677,235.41l-11.945,11.947l162.914,162.913c4.849-2.997,8.95-7.097,11.947-11.947L343.677,235.41z">
                          </path>
                          <path style="fill:#00A8E1;"
                            d="M475.628,96.322H36.372c-11.721,0-22.188,5.624-28.851,14.302l248.478,208.427l248.479-208.427 C497.815,101.946,487.349,96.322,475.628,96.322z">
                          </path>
                          <path style="fill:#0071CE;"
                            d="M475.628,96.322H36.372c-0.939,0-1.869,0.048-2.79,0.119l222.418,186.567L478.417,96.443 C477.496,96.371,476.567,96.322,475.628,96.322z">
                          </path>
                        </g>
                      </svg>
                    </span>
                      </a>
                      <span class="BG">Email</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p style="text-align: center; padding: 1rem; margin: 1rem;"><b>Dr. Vinay Kumar, B.E , M.Tech, P.hD</b></p>
          </div>
        </div>
      </div>
    </div>
  
    <div class="ourClients-container">
      <h1>MEET OUR CLIENTS</h1>
      <div class="ourClients">
        <div class="image" v-for="(client, index) in clients" :key="index">
          <img :src="client.src" :alt="client.alt" :height="client.height" :width="client.width" :class="{'special-image': client.alt === 'Vinva'}">
        </div>
      </div>
    </div>

    <div class="ourClients-container">
      <h1>MEET OUR Edu-CLIENTS</h1>
      <div class="ourClients">
        <div class="image" v-for="(edclient, index) in edclients" :key="index">
          <img :src="edclient.src" :alt="edclient.alt" :height="edclient.height" :width="edclient.width">
        </div>
      </div>
    </div>
  
    <div class="ourPartners-container">
      <h1>MEET OUR ASSOCIATIONS</h1>
      <div class="ourPartners">
        <div class="image" v-for="(partner, index) in partners" :key="index">
          <img :src="partner.src" :alt="partner.alt" :height="partner.height">
        </div>
      </div>
    </div>

    <div>
      <RewardsRecognition/>
    </div>
  </template>
  
  <script>
  import RewardsRecognition from '@/components/RewardsRecognition.vue';
  export default {
    components: {
      RewardsRecognition,
    },
    metaInfo: {
    title: 'About IVIS LABS - Leading AI Innovation',
    meta: [
      {
        name: 'description',
        content: 'Learn about IVIS LABS, a leading AI company founded by computer vision experts. Discover our journey, team, and commitment to innovation.'
      },
      {
        name: 'keywords',
        content: 'IVIS LABS, AI company, computer vision experts, AI innovation, tech company Bangalore, tech company Mysore'
      },
      // Open Graph
      {
        property: 'og:title',
        content: 'About IVIS LABS - AI Innovation Leaders'
      },
      {
        property: 'og:description',
        content: 'Discover the story of IVIS LABS and our mission to transform industries through AI'
      }
    ]
  },
    data() {
      return {
        profilePic: require('../assets/profile-pic.jpg'),
        linkedinUrl: "https://www.linkedin.com/in/vvenkataramana/",
        emailUrl: "mailto:vinay@ivislabs.com",
        clients: [
        { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/aimlware.webp", alt: "AI ML Ware" },
          { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/kixr.png", alt: "Kixr" },
          { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/LGC.jpg", alt: "LGC", height: "100" },
          { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/cumulations.webp", alt: "Cumulations" },
          { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/prudent.png", alt: "Prudent" },
          { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/totalGas.png", alt: "Total Gas", height: "100" },
          { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/infopinelogo.png", alt: "Infopine", height: "100", width: "150" },
          { src: "https://sencilla.in/wp-content/uploads/2022/04/sencilla-logo2.png", alt: "sencilla", height: "100", width: "150" },
          { src: "https://sggpl.com/wp-content/uploads/2024/01/SGG-NEWEST-LOGO-shadow.png", alt: "SGG", height: "75", width: "150" },
          { src: "https://vinvatech.com/wp-content/uploads/2024/07/cropped-vinva-2.png", alt: "Vinva", height: "75", width: "150" },
        ],
        edclients:[
        { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/Atme.png", alt: "Atme" },
          { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/eshwarcollege.png", alt: "Eshwar College" },
          { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/vvce.png", alt: "VVCE", height: "150" },
          { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/Nie.png", alt: "NIE", height: "125" },
          { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/gsss.png", alt: "GSSS" },
          { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/cresta.png", alt: "Cresta", height: "100" },
          { src: require("../assets/jss.png"), alt: "JSS", height: "125"  },
          { src: require("../assets/sapient.png"), alt: "Sapient", height:"150"},
          { src: require("../assets/shantala.png"), alt: "Shantala", height:"125" },
          { src: require("../assets/hindustan.png"), alt: "Hindustan" },
        ],
        partners: [
        { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/aws.png", alt: "AWS", height: "100" },
        { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/aimlware.webp", alt: "AI ML Ware" },
        { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/cumulations.webp", alt: "Cumulations" },
        { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/prudent.png", alt: "Prudent" },
        { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/zoho.png", alt: "Zoho", height: "100" },
        { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/OpenAI-Logo-PNG.png", alt: "OpenAI" },
        { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/microsoft.png", alt: "Microsoft" },
        { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/eres.webp", alt: "ERES" },
        { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/canva.jpeg", alt: "Canva" },
        { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/LGC.jpg", alt: "LGC", height: "100" },
        { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/iitm.png", alt: "IIT Madras" },
        { src: "https://ivislabsdocs.s3.ap-south-1.amazonaws.com/bits-pillani.webp", alt: "BITS Pilani" }
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .typewriter {
  /* background-color: #fff; */
  background-image: url('../assets/background.jpg');
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem;
}

.typewriter p {
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
  letter-spacing: 0.15em;
  display: block;
  margin-bottom: 10px;
  text-align: center;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

.typewriter p:nth-child(1) {
  animation: typing 2s steps(55, end) forwards;
}

.typewriter p:nth-child(2) {
  width: 0;
  animation: typing 2s steps(55, end) forwards;
  animation-delay: 2s;
}

.typewriter p:nth-child(3) {
  width: 0;
  animation: typing 2s steps(65, end) forwards;
  animation-delay: 4s;
}

/* .s-1{
  background-image: url(assets/background.jpg);
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
} */

.s-1 h1{
  color: var(--secondary-color);
  text-align: center;
  margin-top: 1rem;
}

.ss-1-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.intro {
  flex: 1; /* This will make the intro take up available space */
  max-width: 50%; /* Adjust this value to control the width of the intro text */
  font-size: 1.7rem;
  color: var(--secondary-color);
  padding: 0 1rem 1rem;
  line-height: 3rem;
}

.ss-1 p{
  color: var(--secondary-color);
}

.flip-card {
    background-color: transparent;
    width: 400px;
    height: 300px;
    perspective: 1000px;
    font-family: "Nunito", sans-serif;
    position: relative;
    margin: 1rem 1rem 1rem auto;   
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    box-shadow: 0 8px 14px 0 rgba(0,0,0,0.2);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 1rem;
  }
  
  .flip-card-front {
    background: white;
  }

  .flip-card-front img{
    width: 100%;
    height: auto;
    object-fit: cover;
    overflow: hidden;
    border-radius: inherit;
  }
  
  .flip-card-back {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    gap: 0.8rem;
    transform: rotateY(180deg);
}

.Social-Btn {
    width: 4.5em;
    height: 2.5em;
    align-items: baseline;
    justify-content: center;
    border: none;
  
    /* overflow: hidden; */
    border-radius: 7px;
    cursor: pointer;
    transition: all 0.3s;
    fill: white !important;
    position: relative;
  }
  
  .svgContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    backdrop-filter: blur(0px);
    letter-spacing: 0.8px;
    border-radius: 10px;
    transition: all 0.3s;
    border: 1px solid rgba(156, 156, 156, 0.466);
    color: white;
    background: white
  }
  
  .svgContainer > svg {
    width: 1.5rem;
  }
  
  .BG {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 10px;
    pointer-events: none;
    transition: all 0.3s;
  
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: transparent;
    filter: blur(4px);
    z-index: 4;
  }
  .BG::before {
    position: absolute;
    content: "";
    height: 0.6em;
    width: 0.6em;
    bottom: -0.2em;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    background: white;
    opacity: -1;
    z-index: -10;
    transition: 0.3s all;
  }
  .Social-Btn:hover .BG::before {
    opacity: 1;
  }
  .Social-Btn:hover .BG {
    transform: translateY(-130%);
    transform-origin: bottom;
    background: white;
    color: black;
    filter: blur(0px);
  }
  
  .Social-Btn:hover .svgContainer {
    z-index: 20;
    background-color: rgba(156, 156, 156, 0.466);
    filter: blur(0px);
    fill: black;
  }

.title-flex {
    display: flex;
    gap: 0.8rem;
}

.ourClients-container {
  background-color: var(--bg-1);
  padding: 1rem;
}

.ourClients {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-items: center;
  padding: 2rem;
}

.ourClients-container h1, .ourPartners-container h1{
  text-align: center;
  color: var(--secondary-color);
  animation: h1Ani 3s forwards infinite ease-in;
}

.special-image {
  background-color: blue; /* Change this to your desired color */
  padding: 5px; /* Optional: Add some padding */
  border-radius: 8px; /* Optional: Add rounded corners */
  display: inline-block; /* Ensures the background color wraps around the image */
}

@keyframes h1Ani {
  0%, 100%{
    letter-spacing: 0px;
  }
  50%{
    letter-spacing: 5px;
  }
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.image img{
  max-width: 200px;
}

.image img:hover{
  scale: 1.1;
  transition: 0.5s;
}

.ourPartners-container {
  background-color: var(--bg-2);
  padding: 1rem;
}

.ourPartners {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-items: center;
  padding: 2rem;
}

.ourPartners-container h1{
  text-align: center;
  margin: 0;
  padding: 1rem;
}

@media (max-width:860px) {
    .ss-1-container{
      flex-direction: column-reverse;
    }
    .intro{
      max-width: none;
      padding: 2rem;
      font-size: 1.4rem;
    }

    .ourClients{
      grid-template-columns: repeat(3,1fr);
    }
    .ourPartners {
      grid-template-columns: repeat(2,1fr);
    }
  }

  @media (max-width:670px) {
    .typewriter p{
      white-space: normal;
    }

    .typewriter p:nth-child(3), .typewriter p:nth-child(2), .typewriter p:nth-child(1) {
      animation: fadeIn 2s ease-in-out both;
      width: auto;
      font-size: 1rem;
      margin: 0;
      line-height: 2rem;
    }

    .ourClients, .ourPartners{
      grid-template-columns: repeat(2,1fr);
    }
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to{
        opacity: 1;
      }
    }

    .flip-card {
      width: 300px;
      height: 200px;
    }

    .intro {
      color: var(--secondary-color);
      font-size: 1.1rem;
    }

    .s-1 h1{
      font-size: 1.5rem;
    }
  }

  @media (max-width:470px) {
    .ourClients, .ourPartners{
      grid-template-columns: 1fr;
    }
  }
  </style>
  